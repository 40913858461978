// src/pages/Customers.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, Paper } from '@mui/material';

const Customers = () => {
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    axios.get('/adminpanel/api/customers/')
      .then(response => setCustomers(response.data))
      .catch(error => console.error('Error fetching customers:', error));
  }, []);

  const handleActivate = (id) => {
    axios.post(`/adminpanel/api/customers/${id}/activate/`)
      .then(() => alert('Customer activated'))
      .catch(error => console.error('Error activating customer:', error));
  };

  const handleDeactivate = (id) => {
    axios.post(`/adminpanel/api/customers/${id}/deactivate/`)
      .then(() => alert('Customer deactivated'))
      .catch(error => console.error('Error deactivating customer:', error));
  };

  return (
    <Paper style={{ padding: '20px' }}>
      <h2>Customers</h2>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Plan</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customers.map((customer) => (
            <TableRow key={customer.id}>
              <TableCell>{customer.user.username}</TableCell>
              <TableCell>{customer.plan}</TableCell>
              <TableCell>
                <Button onClick={() => handleActivate(customer.id)}>Activate</Button>
                <Button onClick={() => handleDeactivate(customer.id)}>Deactivate</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default Customers;