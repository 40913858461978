// src/components/GlobalStyles.js

import { GlobalStyles as MuiGlobalStyles } from '@mui/material';

const GlobalStyles = () => (
  <MuiGlobalStyles
    styles={{
      body: {
        margin: 0,
        padding: 0,
        backgroundColor: 'background.default', // Use palette from theme
        color: 'text.primary', // Use text color from theme
        fontFamily: 'Roboto, sans-serif', // Ensure consistent font family
      },
    }}
  />
);

export default GlobalStyles;