// src/themes/theme.js

export const lightTheme = {
  colors: {
    primary: '#1976d2',
    primaryDark: '#004ba0',
    primaryLight: '#63a4ff',
    background: '#f0f8ff', // Make sure this is defined
    text: '#333',
    link: '#40E0D0',
    linkHover: '#2F9D98',
    buttonText: '#fff',
    buttonBackground: '#1976d2',
    buttonBorder: '#004ba0',
    buttonHoverBackground: '#0059b2',
    gradientStart: '#5a95ff',
    gradientEnd: '#1e60d9',
  },
  borderRadius: '30px',
  boxShadow: '0 6px 15px rgba(0, 0, 0, 0.15)',
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
};

export const darkTheme = {
  colors: {
    primary: '#90caf9',
    background: '#121212', // Make sure this is defined
    backgroundLight: '#1e1e1e',
    text: '#e0e0e0',
    buttonText: '#90caf9',
    buttonBackground: '#1e1e1e',
    buttonHoverBackground: '#333',
    gradientStart: '#90caf9',
    gradientEnd: '#1e88e5',
  },
  borderRadius: '30px',
  boxShadow: '0 6px 15px rgba(0, 0, 0, 0.15)',
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
};