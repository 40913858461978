// src/App.js
import React, { useState, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { CssBaseline } from '@mui/material';
import { lightTheme, darkTheme } from './themes/theme';
import GlobalStyles from './components/GlobalStyles';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import Dashboard from './pages/Dashboard';
import Customers from './pages/Customers';
import ContactSubmissions from './pages/ContactSubmissions';
import Billing from './pages/Billing';
import NotFoundPage from './pages/NotFoundPage';
import { AuthProvider, AuthContext } from './context/AuthContext';

const Layout = ({ children, toggleTheme, isDarkTheme }) => (
  <>
    <Navbar toggleTheme={toggleTheme} isDarkTheme={isDarkTheme} />
    <div style={{ padding: '20px' }}>{children}</div>
  </>
);

function App() {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const toggleTheme = () => setIsDarkTheme(!isDarkTheme);

  return (
    <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
      <CssBaseline />
      <GlobalStyles />
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <Layout toggleTheme={toggleTheme} isDarkTheme={isDarkTheme}>
                    <Dashboard />
                  </Layout>
                </RequireAuth>
              }
            />
            <Route
              path="/customers"
              element={
                <RequireAuth>
                  <Layout toggleTheme={toggleTheme} isDarkTheme={isDarkTheme}>
                    <Customers />
                  </Layout>
                </RequireAuth>
              }
            />
            <Route
              path="/billing"
              element={
                <RequireAuth>
                  <Layout toggleTheme={toggleTheme} isDarkTheme={isDarkTheme}>
                    <Billing />
                  </Layout>
                </RequireAuth>
              }
            />
            <Route
              path="/contact-submissions"
              element={
                <RequireAuth>
                  <Layout toggleTheme={toggleTheme} isDarkTheme={isDarkTheme}>
                    <ContactSubmissions />
                  </Layout>
                </RequireAuth>
              }
            />
            {/* Corrected NotFoundPage Route */}
            <Route
              path="*"
              element={
                <Layout toggleTheme={toggleTheme} isDarkTheme={isDarkTheme}>
                  <NotFoundPage />
                </Layout>
              }
            />
          </Routes>
          <Footer />
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

function RequireAuth({ children }) {
  const { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated ? children : <Navigate to="/" />;
}

export default App;