// src/components/Navbar.js
import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ThemeSwitcher from './ThemeSwitcher';
import { AuthContext } from '../context/AuthContext';

const Navbar = ({ toggleTheme, isDarkTheme }) => {
  const { isAuthenticated, logout, user } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleDashboardRedirect = () => {
    navigate('/dashboard');
  };

  return (
    <AppBar position="static">
      <Toolbar>
        {/* Make the title clickable to redirect to the dashboard */}
        <Typography
          variant="h6"
          style={{ flexGrow: 1, fontWeight: 'bold', cursor: 'pointer' }}
          onClick={handleDashboardRedirect}
        >
          Orphimuse Admin Panel
        </Typography>

        {isAuthenticated && (
          <Box>
            <Button color="inherit" component={Link} to="/dashboard" style={{ margin: '0 8px' }}>
              Dashboard
            </Button>
            <Button color="inherit" component={Link} to="/customers" style={{ margin: '0 8px' }}>
              Customers
            </Button>
            <Button color="inherit" component={Link} to="/billing" style={{ margin: '0 8px' }}>
              Billing
            </Button>
            <Button color="inherit" component={Link} to="/reports" style={{ margin: '0 8px' }}>
              Reports
            </Button>
            <Button color="inherit" component={Link} to="/contact-submissions" style={{ margin: '0 8px' }}>
              Contact Submissions
            </Button>
          </Box>
        )}

        {isAuthenticated ? (
          <>
            <Typography variant="body1" style={{ margin: '0 8px' }}>
              Welcome, {user?.username || 'User'}
            </Typography>
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          </>
        ) : (
          <Button color="inherit" component={Link} to="/">
            Login
          </Button>
        )}

        {/* Theme Switcher */}
        <ThemeSwitcher toggleTheme={toggleTheme} isDarkTheme={isDarkTheme} />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;