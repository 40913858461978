import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Paper, Divider, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import styled from 'styled-components';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LandingContainer = styled(Box)`
  display: flex;
  flex-direction: row;  /* Two columns on larger screens */
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 0 5%;
  background: linear-gradient(to right, #f5f7fa, #c3cfe2);
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;  /* Stacks content on smaller screens */
    padding: 20px;
  }
`;

const ContentBox = styled(Box)`
  max-width: 600px;
  text-align: left;  /* Align text to the left */
  margin-right: 50px;  /* Margin to separate from login box */
  z-index: 2;
  color: #333;

  @media (max-width: 768px) {
    margin-right: 0;
    text-align: center;  /* Center text on smaller screens */
    margin-bottom: 30px; /* Add margin bottom for spacing */
  }
`;

const LoginBox = styled(Paper)`
  padding: 40px;
  width: 400px;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 12px;
  z-index: 2;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 20px !important;
  width: 100%;
`;

const SocialButton = styled(Button)`
  margin-bottom: 10px !important;
  width: 100%;
  font-weight: 500 !important;
`;

const Logo = styled.div`
  font-size: 32px;
  color: #4a90e2;
  font-weight: bold;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 26px;
  }

  @media (max-width: 480px) {
    font-size: 22px;
  }
`;

const StyledDivider = styled(Divider)`
  width: 100%;
  margin: 30px 0;
  border-top: 1px solid #ddd;
`;

const LandingPage = () => {
  const theme = useTheme();
  const { isAuthenticated, login } = useContext(AuthContext);
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_ORPHIMUSE_BE_API_URL;
  const loginEndpoint = `${apiUrl}/api/auth/login/`;

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.post(loginEndpoint, {
        email,
        password,
      });

      const { key } = response.data;
      localStorage.setItem('token', key);
      login(key, { username: email });

      toast.success('Logged in successfully!');
      navigate('/dashboard');
    } catch (error) {
      console.error('Login failed:', error);
      setError('Invalid email or password. Please try again.');
      toast.error('Login failed. Please check your credentials.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <LandingContainer theme={theme}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <ContentBox>
        <Logo theme={theme}>Orphimuse Admin</Logo>
        <Typography variant="h2" gutterBottom style={{ fontWeight: 700, color: '#4a4a4a' }}>
          Welcome to the Orphimuse Admin Panel
        </Typography>
        <Typography
          variant="h6"
          color="textSecondary"
          style={{ maxWidth: '600px', lineHeight: '1.6' }}
        >
          Manage your customers, billing, reports, and more, all from one place.
        </Typography>
      </ContentBox>

      <LoginBox theme={theme}>
        <Typography
          variant="h5"
          gutterBottom
          style={{ marginBottom: '20px', fontWeight: 600 }}
        >
          Admin Login
        </Typography>
        <StyledTextField
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          aria-label="Email"
        />
        <StyledTextField
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          aria-label="Password"
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginBottom: '20px', padding: '10px 0' }}
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Login'}
        </Button>
        {error && <Typography color="error">{error}</Typography>}

        <Typography variant="body2" style={{ marginBottom: '10px' }}>Or login with</Typography>
        <SocialButton
          variant="outlined"
          color="primary"
          startIcon={<GoogleIcon />}
          fullWidth
        >
          Login with Google
        </SocialButton>
        <SocialButton
          variant="outlined"
          color="primary"
          startIcon={<FacebookIcon />}
          fullWidth
        >
          Login with Facebook
        </SocialButton>
        <StyledDivider />
        <Typography
          variant="body2"
          style={{ cursor: 'pointer', color: theme.palette.primary.main }}
        >
          Forgot Password?
        </Typography>
      </LoginBox>
    </LandingContainer>
  );
};

export default LandingPage;