// src/pages/ContactSubmissions.js

import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ContactSubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [error, setError] = useState(null);
  const {logout } = useContext(AuthContext); // Use context to get user and logout
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_ORPHIMUSE_BE_API_URL;
  const contactUsEndpoint = `${apiUrl}/api/contact/all/`;

  useEffect(() => {
    const fetchSubmissions = async () => {
      const token = localStorage.getItem('token');  // Retrieve token from localStorage

      if (!token) {
        setError('User is not authenticated. Please log in.');
        console.error('No token found. User is not authenticated.');
        return;
      }

      try {
        const response = await axios.get(contactUsEndpoint, {
          headers: { Authorization: `Bearer ${token}` },  // Include token for authentication
        });
        setSubmissions(response.data);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          setError('Unauthorized. Please log in again.');
          console.error('Unauthorized access - Token might be expired or invalid.');
          localStorage.removeItem('token');  // Clear token
          logout();  // Log out the user using context
          navigate('/');  // Redirect to login page
        } else {
          setError('Error fetching contact submissions. Please try again.');
        }
        console.error('Error fetching contact submissions:', err);
      }
    };

    fetchSubmissions();
  }, [contactUsEndpoint, logout, navigate]);

  return (
    <div>
      <h2>Contact Submissions</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {submissions.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Message</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {submissions.map((submission) => (
              <tr key={submission.id}>
                <td>{submission.name}</td>
                <td>{submission.email}</td>
                <td>{submission.message}</td>
                <td>{new Date(submission.created_at).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !error && <p>No submissions available.</p> // Show message if no submissions
      )}
      <Button variant="contained" color="secondary" onClick={logout}>
        Logout
      </Button>
    </div>
  );
};

export default ContactSubmissions;