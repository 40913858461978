// src/pages/Billing.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, Paper } from '@mui/material';

const Billing = () => {
  const [billing, setBilling] = useState([]);

  useEffect(() => {
    axios.get('/adminpanel/api/billing/')
      .then(response => setBilling(response.data))
      .catch(error => console.error('Error fetching billing:', error));
  }, []);

  const markAsPaid = (id) => {
    axios.post(`/adminpanel/api/billing/${id}/mark_as_paid/`)
      .then(() => alert('Billing marked as paid'))
      .catch(error => console.error('Error marking billing as paid:', error));
  };

  return (
    <Paper style={{ padding: '20px' }}>
      <h2>Billing</h2>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {billing.map((bill) => (
            <TableRow key={bill.id}>
              <TableCell>{bill.customer.user.username}</TableCell>
              <TableCell>{bill.amount}</TableCell>
              <TableCell>{bill.status}</TableCell>
              <TableCell>
                {bill.status !== 'Paid' && <Button onClick={() => markAsPaid(bill.id)}>Mark as Paid</Button>}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default Billing;