import React, { useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../context/AuthContext';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const DashboardContainer = styled.div`
  padding: 40px;
  background-color: ${(props) => props.theme.colors.background};
  min-height: 100vh;
  margin-top: 60px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-size: 32px;
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
`;

const WelcomeMessage = styled.p`
  font-size: 18px;
  color: ${(props) => props.theme.colors.text};
`;

const Dashboard = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <DashboardContainer>
      <Header>
        <Title>Dashboard</Title>
        <Button variant="contained" color="secondary" onClick={handleLogout}>
          Logout
        </Button>
      </Header>
      <WelcomeMessage>
        Welcome back, {user ? user.username : 'Guest'}!
      </WelcomeMessage>
    </DashboardContainer>
  );
};

export default Dashboard;