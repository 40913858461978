// src/pages/NotFoundPage.js

import React from 'react';
import styled from 'styled-components';
import { Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Styled Components
const NotFoundContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  padding: 40px; /* Adjust padding */
  margin-top: 60px; /* Adjust margin to allow space for navbar */
  height: 100%; /* Use full available height, not fixed viewport height */
`;

const Title = styled(Typography)`
  font-size: 72px;
  font-weight: bold;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.primary};
`;

const Subtitle = styled(Typography)`
  font-size: 24px;
  margin-bottom: 30px;
  color: ${(props) => props.theme.colors.text};
`;

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/'); // Redirect to home page
  };

  return (
    <NotFoundContainer>
      <Title>404</Title>
      <Subtitle>Oops! The page you're looking for doesn't exist.</Subtitle>
      <Button variant="contained" color="primary" onClick={handleGoHome}>
        Go Back Home
      </Button>
    </NotFoundContainer>
  );
};

export default NotFoundPage;